import v from 'voca';

export const UnitColumns = Object.freeze({
  SUITE_NUMBER: 'suiteNumber',
  FLOOR: 'floor',
  BEDROOMS: 'bedrooms',
  BATHROOMS: 'bathrooms',
  PROJECT: 'project',
  BUILDING_MODEL: 'buildingModel',
  SIZE: 'size',
  AVAILABILITY: 'availability'
});

export const DefaultUnitColumns = Object.freeze([
  UnitColumns.SUITE_NUMBER,
  UnitColumns.FLOOR,
  UnitColumns.BEDROOMS,
  UnitColumns.BATHROOMS,
  UnitColumns.PROJECT,
  UnitColumns.BUILDING_MODEL,
  UnitColumns.SIZE,
  UnitColumns.AVAILABILITY
]);

export function getUnitFilters(currentCompany, filters, query) {
  const filter = { companyId: currentCompany.id };

  if (!v.isBlank(query)) filter.query = query;

  if (filters.projects?.length) {
    filter.projectIds = filters.projects.map((project) => project.id);
  }

  if (filters.buildingModels?.length) {
    filter.buildingModelIds = filters.buildingModels.map((buildingModel) => buildingModel.id);
  }

  if (filters.bedrooms?.length) filter.bedroomsValues = filters.bedrooms;

  if (filters.bathrooms?.length) filter.bathroomsValues = filters.bathrooms;

  if (filters.availabilities?.length) filter.availabilities = filters.availabilities;

  return filter;
}
