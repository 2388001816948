import v from 'voca';

import { QuickPossessionStages } from '../../../../constants';

export const QuickPossessionColumns = Object.freeze({
  COMMUNITY: 'community',
  MODEL: 'model',
  PRODUCT_TYPE: 'productType',
  ADDRESS: 'address',
  SIZE: 'size',
  BEDS: 'beds',
  BATHS: 'baths',
  PRICE: 'price',
  AVAILABILITY: 'availability',
  STATUS: 'status',
  INTERESTS: 'interests',
  LAST_MODIFIED: 'lastModified',
  LAST_CREATED: 'lastCreated'
});

export const DefaultQuickPossessionColumns = Object.freeze([
  QuickPossessionColumns.COMMUNITY,
  QuickPossessionColumns.MODEL,
  QuickPossessionColumns.PRODUCT_TYPE,
  QuickPossessionColumns.ADDRESS,
  QuickPossessionColumns.SIZE,
  QuickPossessionColumns.BEDS,
  QuickPossessionColumns.BATHS,
  QuickPossessionColumns.PRICE,
  QuickPossessionColumns.AVAILABILITY
]);

export function getQuickPossessionFilters(
  currentCompany, filters, query, showOnlyScheduledToArchive
) {
  const filter = { companyId: currentCompany.id };

  filter.stage = showOnlyScheduledToArchive
    ? QuickPossessionStages.SCHEDULED_TO_ARCHIVE
    : QuickPossessionStages.ACTIVE;

  if (!v.isBlank(query)) filter.query = query;

  if (filters.communities?.length) {
    filter.communityIds = filters.communities.map((community) => community.id);
  }

  if (filters.models?.length) {
    filter.modelIds = filters.models.map((model) => model.id);
  }

  if (filters.productTypes?.length) {
    filter.productTypeIds = filters.productTypes.map((productType) => productType.id);
  }

  if (filters.beds?.length) {
    filter.totalBedsValues = filters.beds;
  }

  if (filters.baths?.length) {
    filter.totalBathsValues = filters.baths;
  }

  return filter;
}
